<template>
  <div>login</div>
</template>

<script>
import { defineComponent } from "vue";
import { stringify } from "querystring";
import { authUri, authCallbackUri, clientId } from "@/config/env";

export default defineComponent({
  created() {
    window.location.replace(
      `${authUri}?${stringify({
        client_id: clientId,
        response_type: "token",
        redirect_uri: authCallbackUri,
      })}`
    );
  },
});
</script>
